<template>
  <b-form-group
    label="Parkur Tipi"
    label-for="id_parkour_types"
  >
    <v-select
      id="id_parkour_types"
      v-model="filterData.id_parkour_types"
      :options="parkourTypes"
      label="title"
      :reduce="item => item.id"
      placeholder="Seçiniz"
      multiple
    />
  </b-form-group>
</template>

<script>
import {
  BFormGroup,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  name: 'MachineTypes',
  components: {
    BFormGroup,
    vSelect,
  },
  computed: {
    filterData() {
      return this.$store.getters['customers/filterData']
    },
    parkourTypes() {
      return this.$store.getters['parkourTypes/dataList']
    },
  },
  created() {
    this.getSectors()
  },
  methods: {
    getSectors() {
      this.$store.dispatch('parkourTypes/getDataList', {
        select: [
          'parkour_types.id AS id',
          'parkour_types.title AS title',
        ],
      })
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
