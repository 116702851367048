<template>
  <b-form-group
    label="İlk Temas"
    label-for="id_meets"
  >
    <v-select
      id="id_meets"
      v-model="filterData.id_meets"
      :options="meets"
      label="title"
      :reduce="meet => meet.id"
      placeholder="Seçiniz"
    />
  </b-form-group>
</template>

<script>
import {
  BFormGroup,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  name: 'Meet',
  components: {
    BFormGroup,
    vSelect,
  },
  computed: {
    filterData() {
      return this.$store.getters['customers/filterData']
    },
    meets() {
      return this.$store.getters['meets/dataList']
    },
  },
  created() {
    this.getMeets()
  },
  methods: {
    getMeets() {
      this.$store.dispatch('meets/getDataList', {
        select: [
          'meets.id AS id',
          'meets.title AS title',
        ],
      })
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
