<template>
  <b-form-group
    label="Sektör"
    label-for="id_sectors"
  >
    <v-select
      id="id_sectors"
      v-model="filterData.id_sectors"
      :options="sectors"
      label="title"
      :reduce="sector => sector.id"
      placeholder="Seçiniz"
      multiple
    />
  </b-form-group>
</template>

<script>
import {
  BFormGroup,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  name: 'Sector',
  components: {
    BFormGroup,
    vSelect,
  },
  computed: {
    filterData() {
      return this.$store.getters['customers/filterData']
    },
    sectors() {
      return this.$store.getters['sectors/dataList']
    },
  },
  created() {
    this.getSectors()
  },
  methods: {
    getSectors() {
      this.$store.dispatch('sectors/getDataList', {
        select: [
          'sectors.id AS id',
          'sectors.title AS title',
        ],
      })
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
