<template>
  <b-form-group
    label="Ülke"
    label-for="id_countries"
  >
    <v-select
      id="id_countries"
      v-model="filterData.id_countries"
      :options="countries"
      label="title"
      :reduce="country => country.id"
      placeholder="Seçiniz"
      @input="getCities"
    />
  </b-form-group>
</template>

<script>
import {
  BFormGroup,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  name: 'Country',
  components: {
    BFormGroup,
    vSelect,
  },
  computed: {
    filterData() {
      return this.$store.getters['customers/filterData']
    },
    countries() {
      return this.$store.getters['countries/dataList']
    },
  },
  created() {
    this.getCountries()
  },
  methods: {
    getCountries() {
      this.$store.dispatch('countries/getDataList', {
        select: [
          'countries.id AS id',
          'countries.title AS title',
        ],
        order_by: ['countries.is_default', 'ASC'],
      })
    },
    getCities() {
      this.filterData.id_cities = this.filterData.id_cities ? null : this.filterData.id_cities
      this.$store.dispatch('cities/getDataList', {
        select: [
          'cities.id AS id',
          'cities.title AS title',
        ],
        where: {
          'cities.id_countries': this.filterData.id_countries,
        },
      })
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
